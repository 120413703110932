import { getUserRoles } from '../auth';
import { ROLES } from './constants';

const getMostPrivilegedRole = async () => {
  const roles = (await getUserRoles()) as ROLES[];

  const map = roles.reduce((acc, cur) => {
    acc[cur] = cur;
    return acc;
  }, {} as Record<ROLES, ROLES>);

  let role: ROLES;
  if ((role = map[ROLES.DEVELOPER])) return role;
  if ((role = map[ROLES.ADMIN])) return role;
  if ((role = map[ROLES.CORPORATE])) return role;
  if ((role = map[ROLES.FIELD_COMPANY])) return role;
  if ((role = map[ROLES.FIELD_FRANCHISE])) return role;
  return ROLES.NO_REPORTING;
};

export default getMostPrivilegedRole;
